import React, { useState, useEffect } from "react";

import Carbon from "components/Carbon";
import Comments from "components/Post/Comments";
import Done from "components/Post/Done";

import ReadMore from "components/Post/ReadMore";

import {
  addUpvote,
  retrieveUpvotes,
  userActivityTutorialRef,
  updateUserActivity,
} from "api/db";
import { auth } from "api/firebase";

import { ArticleWrapper, Project, Share, PostAd, Feedback } from "./styles";

import Markdown from "react-remarkable-with-plugins";
import hljs from "highlight.js";

import { BrowserView } from "react-device-detect";

import AuthUserContext from "../Session/AuthUserContext";

import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  WhatsappShareButton,
  WhatsappIcon,
  RedditShareButton,
  RedditIcon,
  EmailShareButton,
  EmailIcon,
  LinkedinShareButton,
  LinkedinIcon,
} from "react-share";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSortUp } from "@fortawesome/free-solid-svg-icons";
import {
  fetchActiveCohort,
  fetchUserDataByUid,
  checkUserDataForCohort,
} from "../../api/db";

const WebArticleComponent = ({
  title,
  id,
  content,
  language,
  toc,
  projectId,
  filteredTutorialSuggestions,
}) => (
  <AuthUserContext.Consumer>
    {(authUser) =>
      authUser ? (
        <WebArticle
          title={title}
          id={id}
          content={content}
          language={language}
          toc={toc}
          projectId={projectId}
          uid={authUser.uid}
          filteredTutorialSuggestions={filteredTutorialSuggestions}
        />
      ) : (
        <WebArticle
          title={title}
          id={id}
          content={content}
          language={language}
          toc={toc}
          projectId={projectId}
          uid={null}
          filteredTutorialSuggestions={filteredTutorialSuggestions}
        />
      )
    }
  </AuthUserContext.Consumer>
);

const WebArticle = ({
  title,
  id,
  content,
  language,
  toc,
  projectId,
  uid,
  filteredTutorialSuggestions,
}) => {
  const [upvoteCount, setUpvoteCount] = useState(0);
  const [tutorialCompleted, setTutorialCompleted] = useState(false);
  const [tutorialStarted, setTutorialStarted] = useState(false);
  const [isActiveCohortMember, setIsActiveCohortMember] = useState(false);

  const highlight = (str, lang) => {
    if (lang && hljs.getLanguage(lang)) {
      try {
        return hljs.highlight(lang, str).value;
      } catch (err) {
        console.error(err);
      }
    }

    try {
      return hljs.highlightAuto(str).value;
    } catch (err) {
      console.error(err);
    }

    return "";
  };

  const url = `https://enlight.nyc/projects/${id}`;

  useEffect(() => {
    retrieveUpvotes(id).then((snapshot) => {
      let upvotes = 0;
      snapshot.forEach(function() {
        upvotes++;
      });
      setUpvoteCount(upvotes);
    });

    if (uid) {
      // fetchActiveCohort().once("value", (snapshot) => {
      //   let cohortNum = snapshot.val();
      //   fetchUserDataByUid(uid).once("value", (snapshot) => {
      //     let userData = snapshot.val();
      //     if (userData && userData.tags && userData.tags != undefined) {
      //       let cohortTag = checkUserDataForCohort(userData);
      //       if (cohortTag.match(/(\d+)/)[0] == cohortNum) {
      //         setIsActiveCohortMember(true);
      //       }
      //     }
      //   });
      // });
      userActivityTutorialRef(uid, projectId).once("value", (snapshot) => {
        let data = snapshot.val();
        if (data) {
          data = Object.values(data);
          setTutorialStarted(true);
          if (data[0]["tutorialCompleted"]) {
            setTutorialCompleted(true);
          } else {
            updateUserActivity(uid, projectId, tutorialCompleted);
          }
        }
      });
    }

    let el = document.getElementById("tutorial");
    let links = el.getElementsByTagName("a");
    for (let i = 0; i < links.length; i++) {
      links[i].target = "_blank";
    }
  }, [uid]);

  return (
    <>
      <PostAd>
        <Carbon carbonUrl="https://cdn.carbonads.com/carbon.js?serve=CK7D6K7W&placement=enlightnyc" />
      </PostAd>
      <ArticleWrapper id="tutorial" className="text-gray-800">
        <Share>
          <FontAwesomeIcon
            onClick={() => {
              let uid = auth.currentUser.uid;
              setUpvoteCount(upvoteCount + 1);
              addUpvote(uid, id, title, language);
            }}
            icon={faSortUp}
            size="3x"
          />{" "}
          <br />
          <span>{upvoteCount}</span> <br />
          <small>upvotes</small>
          <br />
          <br />
          <TwitterShareButton url={url} title={title}>
            <TwitterIcon size={40} round />
          </TwitterShareButton>
          <FacebookShareButton url={url} quote={title}>
            <FacebookIcon size={40} round />
          </FacebookShareButton>
          <RedditShareButton url={url} title={title}>
            <RedditIcon size={40} round />
          </RedditShareButton>
          <WhatsappShareButton url={url} title={title}>
            <WhatsappIcon size={40} round />
          </WhatsappShareButton>
          <LinkedinShareButton url={url} title={title}>
            <LinkedinIcon size={40} round />
          </LinkedinShareButton>
          <EmailShareButton url={url} title={title}>
            <EmailIcon size={40} round />
          </EmailShareButton>
        </Share>
        {/* <TableOfContents toc={toc} /> */}
        <Project>
          <BrowserView>
            <ReadMore
              projectId={projectId}
              projectSlug={id}
              tutorialStarted={tutorialStarted}
            />
          </BrowserView>

          <Markdown options={{ highlight, html: true }}>{content}</Markdown>
        </Project>

        {/* don't display done for cohort members */}

        {!isActiveCohortMember ? (
          <BrowserView>
            <Done
              title={title}
              projectId={projectId}
              tutorialCompleted={tutorialCompleted}
              filteredTutorialSuggestions={filteredTutorialSuggestions}
              projectSlug={id}
            />
          </BrowserView>
        ) : null}
      </ArticleWrapper>
      {/* <Feedback>
        Have questions? Join the Enlight{" "}
        <a href="https://discord.gg/8FwTpfN"> community</a> to chat with our
        team and other makers! Ask for help in the #help channel.
      </Feedback> */}
      <Comments title={title} language={language} id={id} />
    </>
  );
};

export default WebArticleComponent;
