import React, { Component } from "react";
import styled from "styled-components";

import { Link } from "gatsby";
import IBuiltThis from "./IBuiltThis";
import AuthUserContext from "components/Session/AuthUserContext";

// import Label from "components/Elements/Label";

// import { Row, Col } from "react-grid-system";
import {
  Card,
  Title,
  Left,
  Right,
  Middle,
  Img
} from "components/Elements/Card";

import { updateUserActivity } from "api/db";

const Container = styled.div`
  text-align: center;
  margin: 36px 0px;
`;

// const Button = styled.button`
//   padding: 8px;
//   font-size: 24px;
//   cursor: pointer;
//   background: #fff;
//   color: #000;
//   border: 4px solid #0394fc;
//   margin-right: 8px;
//   border-radius: 4px;
//   transition: all 300ms ease;
//   font-weight: bold;
//   &:hover {
//     opacity: 0.8;
//     background: #0394fc;
//     color: #fff;
//   }
// `;

const ModalDiv = styled.div`
  z-index: 2;
  background-color: white;
  -moz-box-shadow: 0 0 15px #e4e4e4;
  -webkit-box-shadow: 0 0 15px #e4e4e4;
  box-shadow: 0 0 15px #e4e4e4;
  padding: 15px;
  width: 90%;
  
  position: fixed;
  z-index: 500;
  left: 5%;
  top: 5%;
  box-sizing: border-box;
  @media only screen and (max-width: 1100px) {
    overflow: scroll;
    overflow-x: hidden;
  }
  @media only screen and (min-width: 768px and min-height: 768px) {
    height: 50%;
  }

  .exit {
    position: absolute;
    right: 12px;
    top: 10px;
    cursor: pointer;
  }
`;

// function projectClick(id) {
//   if (process.env.NODE_ENV === "production") {
//     typeof window !== "undefined" &&
//       window.gtag("event", "click", {
//         "event_label": id,
//         "event_category": "Recommendation"
//       })
//   }
// }

function Project(props) {
  return (
    <Link to={props.url}>
      <Card
        style={{
          height: "220px"
        }}
        // onClick={() => {
        //   projectClick(props.id)
        // }}
      >
        <Img style={{ height: "110px" }} src={props.img} />
        <Title>{props.title}</Title>
        <Left>{props.language}</Left>
        <Right>{props.difficulty}</Right>
      </Card>
    </Link>
  );
}

const DoneComponent = ({
  title,
  projectId,
  tutorialCompleted,
  filteredTutorialSuggestions,
  projectSlug
}) => (
  <AuthUserContext.Consumer>
    {authUser =>
      authUser ? (
        <Done
          title={title}
          uid={authUser.uid}
          projectId={projectId} // numericalId
          tutorialCompleted={tutorialCompleted}
          filteredTutorialSuggestions={filteredTutorialSuggestions}
          projectSlug={projectSlug}
        />
      ) : null
    }
  </AuthUserContext.Consumer>
);

class Done extends Component {
  constructor(props) {
    super(props);

    this.state = {
      clicked: false,
      showModal: false,
      filteredTutorialSuggestions: []
    };
    this.onDoneClicked = this.onDoneClicked.bind(this);
  }

  componentDidMount() {
    let nextThree = [];
    let i = 0;
    let filteredTutorials = [];
    this.props.filteredTutorialSuggestions.map((tutorial, index) => {
      if (this.props.title != tutorial.node.title) {
        filteredTutorials.push(tutorial);
      }
    });
    let length = filteredTutorials.length;
    let chosen = [];
    while (i < length && i < 3) {
      let num = parseInt(Math.random() * length);
      if (!chosen.includes(num)) {
        chosen.push(num);
        nextThree.push(filteredTutorials[num]);
        i++;
      }
    }
    this.setState({
      filteredTutorialSuggestions: nextThree
    });
  }

  onDoneClicked() {
    if (!this.props.tutorialCompleted) {
      updateUserActivity(this.props.uid, this.props.projectId, true);
    }
    this.setState({
      clicked: true,
      showModal: true
    });
    // if (process.env.NODE_ENV === "production") {
    //   typeof window !== "undefined" &&
    //     window.gtag("event", "click", {
    //       event_label: this.props.projectSlug,
    //       event_category: "Done Button"
    //     });
    // }
  }

  render() {
    const transition = this.state.showModal ? "modal" : "modal hide";

    return (
      <div>
        <Container>
        <button
            className={`w-full flex items-center justify-center px-8 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-blue-600 hover:bg-blue-500 focus:outline-none focus:border-blue-800 focus:shadow-outline-blue transition duration-150 ease-in-out md:py-4 md:text-lg md:px-10 cursor-pointer`}
            onClick={this.onDoneClicked}
          >
            {this.props.tutorialCompleted || this.state.clicked
              ? `Congrats — you've completed ${this.props.title}!`
              : `I'm done with ${this.props.title}!`}
          </button>
          {/* <Button onClick={this.onDoneClicked}>
            {this.props.tutorialCompleted || this.state.clicked
              ? `Congrats — you've completed ${this.props.title}!`
              : `I'm done with ${this.props.title}!`}
          </Button> */}
        </Container>
        <ModalDiv className={transition}>
          <span
            className="exit"
            onClick={() => this.setState({ showModal: false })}
          >
            &#10005;
          </span>
          <IBuiltThis title={this.props.title} projectSlug={this.props.projectSlug} numericalId={this.props.projectId}/>
          {/* <Container>
            <Label>
              Here is what's next
            </Label>
          </Container>
          <Row>
            {this.state.filteredTutorialSuggestions.map((tutorial, index) => (
              <Col
                offset={{
                  lg:
                    this.state.filteredTutorialSuggestions.length > 2 &&
                    index == 0
                      ? 0
                      : this.state.filteredTutorialSuggestions.length > 1 &&
                        index == 0
                      ? 2
                      : index == 0
                      ? 4
                      : 0
                }}
                lg={4}
                sm={6}
                key={index}
              >
                <Project
                  url={`/projects/${tutorial.node.id}`}
                  id={tutorial.node.id}
                  img={tutorial.node.img}
                  title={tutorial.node.title}
                  author={tutorial.node.author}
                  difficulty={tutorial.node.difficulty}
                  language={tutorial.node.language}
                />
              </Col>
            ))}
          </Row> */}
        </ModalDiv>
      </div>
    );
  }
}

export default DoneComponent;
