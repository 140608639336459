import React, { Component } from "react";
import { graphql } from "gatsby";
import Helmet from "react-helmet";

import { PostContainer } from "components/Post/styles";
import Header from "components/Post/Header";
import WebArticleComponent from "components/Post/WebArticle";

// import { fetchUserData, updateProjectRef } from "api/db"

class EnlightProject extends Component {
  // componentDidMount() {
  //   // let netlify = document.getElementsByClassName("netlify");
  //   // netlify[0].addEventListener("click", linkClick);
  //   // netlify[1].addEventListener("click", linkClick);

  //   let netlify_0 = document.getElementById("netlify_0");
  //   let netlify_1 = document.getElementById("netlify_1");

  //   if (netlify_0) {
  //   netlify_0.addEventListener("click", linkClick);
  //   netlify_1.addEventListener("click", linkClick);
  //   }

  //   function linkClick() {
  //     if (process.env.NODE_ENV === "production") {
  //       typeof window !== "undefined" &&
  //         window.gtag("event", "click", {
  //           "event_label": "Netlify",
  //           "event_category": "Affiliates"
  //         });
  //     };
  //   };

  //   let weather_0 = document.getElementById("weather_0");

  //   if (weather_0) {
  //   weather_0.addEventListener("click", apiClick);
  //   }
    
  //   function apiClick() {
  //     if (process.env.NODE_ENV === "production") {
  //       typeof window !== "undefined" &&
  //         window.gtag("event", "click", {
  //           "event_label": "Geolocation API",
  //           "event_category": "Affiliates"
  //         });
  //     };
  //   };
  // }

  render() {
    // const post = this.props.data.mdx;
    const post = this.props.data.tutorial;
    let filteredTutorialSuggestions = this.props.data.allTutorial.edges;

    // const filteredTutorialSuggestions = 

    // const nextThree = categoryTutorials.sort((a,b) => {
    //   return (a.node.date > b.node.date) ? -1 : 1;
    // }).slice(0, (categoryTutorials.length >= 3) ? 3 : categoryTutorials.length);

    return (
      <PostContainer>
        <Helmet
          title={`${post.title} with ${post.language} | Enlight`}
          meta={[
            { name: "description", content: `${post.description}` },
            {
              name: "og:title",
              content: `${post.title} with ${post.language}`,
            },
            {
              name: "og:url",
              content: `https://enlight.nyc/projects/${post.id}`,
            },
            {
              name: "og:description",
              content: `${post.description}`,
            },
            {
              name: "og:image",
              content: `${post.img}`,
            },
            { name: "twitter:card", content: "summary" },
            { name: "twitter:site", content: "@tryenlight" },
            {
              name: "twitter:title",
              content: `${post.title} with ${post.language}`,
            },
            {
              name: "twitter:url",
              content: `https://enlight.nyc/projects/${post.id}`,
            },
            {
              name: "twitter:description",
              content: `${post.description}`,
            },
            {
              name: "twitter:image",
              content: `${post.img}`,
            },
          ]}
        >
          {post.id == "build-a-personal-website" &&
          post.author_uid == "pMI8ctvouzfEkYzMw1m2ffNT6203" ? (
            <link
              rel="canonical"
              href="https://www.natecation.com/making-a-website-for-free/"
              data-baseprotocol="https:"
              data-basehost="natecation.com"
            />
          ) : null}
        </Helmet>
        <Header
          title={post.title}
          description={post.description}
          writer={post.author}
          writerId={post.author_username}
          category={post.category}
          language={post.language}
          difficulty={post.difficulty}
          date={post.date}
          id={post.id}
          img={post.img}
          source={post.sourceURL}
          demourl={post.demoURL}
        />
        <WebArticleComponent
          title={post.title}
          id={post.id}
          language={post.language}
          content={post.rawBody}
          toc={post.toc}
          projectId={post.projectId}
          filteredTutorialSuggestions={filteredTutorialSuggestions}
        />
      </PostContainer>
    );
  }
}

export default EnlightProject;

export const projectPageQuery = graphql`
  query EnlightWebProjectBySlug($id: String!, $cat: String!, $diff: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    tutorial(id: { eq: $id }) {
      author
      author_uid
      author_username
      category
      date
      demoURL
      description
      difficulty
      id
      img
      language
      rawBody
      mdxBody
      sourceURL
      template
      timeToRead
      title
      projectId
      toc {
        items {
          items {
            items {
              title
              url
            }
            title
            url
          }
          title
          url
        }
      }
    }
    allTutorial(filter: { published: { eq: true }, category: { eq: $cat }, difficulty: {eq: $diff} }) {
      edges {
        node {
          id
          img
          date
          category
          title
          language
          difficulty
        }
      }
    }
  }
`;
