export const LANDING = "/";
export const SIGN_UP = "/signup";
export const SIGN_IN = "/login";
export const PASSWORD_FORGET = "/pw-forget";
export const HOME = "/home";
export const ACCOUNT = "/account";
export const ABOUT = "/about";
export const TRACKS = "/start";
export const SUBMIT = "/submit";
export const CONTRIBUTE = "/contribute";
export const SUCCESS = "/success";
export const REQUEST = "/request";
export const CHAT = "/chat";
export const ML = "/category/data-science";
export const WEB = "/category/web-development";
export const ONBOARDING = "/onboarding/questions/hello"
