import styled from "styled-components";

export const Card = styled.div`
  height: 320px;
  margin: 20px;
  border-radius: 4px;
  background: #fcfcfc;
  -moz-box-shadow: 0 0.2rem 3rem rgba(0, 0, 0, 0.08);
  -webkit-box-shadow: 0 0.2rem 1rem rgba(0, 0, 0, 0.08);
  box-shadow: 0 0.2rem 1rem rgba(0, 0, 0, 0.08);
  transition: all 200ms ease;

  &:active,
  &:focus,
  &:hover {
    -moz-box-shadow: 0 0.2rem 3rem rgba(0, 0, 0, 0.2);
    -webkit-box-shadow: 0 0.2rem 1rem rgba(0, 0, 0, 0.2);
    box-shadow: 0 0.2rem 1rem rgba(0, 0, 0, 0.2);
  }
`;

export const Title = styled.h1``;

export const Left = styled.div`
  position: absolute;
  left: 48px;
  bottom: 36px;
  padding: 8px;
`;

export const Right = styled.span`
  position: absolute;
  right: 48px;
  bottom: 36px;
  padding: 8px;
`;

export const Img = styled.div`
  background-image: url(${props => props.src});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  height: 175px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
`;
