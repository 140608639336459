import React, { Component } from "react";
import { Link } from "gatsby";
import Markdown from "react-remarkable-with-plugins";

import {
  onCommentAdded,
  retrievePostsUserCommented,
  pushComment,
  fetchUserDataByUid
} from "api/db";
import { auth } from "api/firebase";

import { updateByPropertyName } from "helpers";

//import Button from "components/Elements/Button";

class Comments extends Component {
  constructor(props) {
    super(props);
    this.state = {
      postId: this.props.id,
      postTitle: this.props.title,
      postLanguage: this.props.language,
      comment: "",
      comments: [],
      users: {},
      comment_count: 0
    };
  }

  componentDidMount() {
    this.getUserProfilesOfComments();

    onCommentAdded(this.state.postId, snapshot => {
      this.setState(prevState => ({
        comments: [...prevState.comments, snapshot.val()]
      }));

      this.setState({
        comment_count: this.state.comments.length
      });
    });
  }

  getUserProfilesOfComments = () => {
    let id = this.state.postId;
    var context = this;
    retrievePostsUserCommented().then(snapshot => {
      snapshot.forEach(function(snapshot1) {
        snapshot1.forEach(function(snapshot2) {
          fetchUserDataByUid(snapshot2.val().uid).on("value", function(
            snapshot3
          ) {
            let key = snapshot3.key;
            let data = snapshot3.val();
            context.setState(prevState => ({
              users: { ...prevState.users, [key]: data }
            }));
          });
        });
      });
    });
  };

  onCommentSubmit = event => {
    event.preventDefault();
    let context = this;
    auth.onAuthStateChanged(function(user) {
      if (user) {
        context.submitComment(user.uid);
      } else {
        alert("You must be logged in to comment.");
      }
    });
  };

  submitComment = uid => {
    if (/^ *$/.test(this.state.comment)) {
      alert("Your comment cannot be blank.");
    } else {
      pushComment(
        uid,
        this.state.comment,
        this.state.postTitle,
        this.state.postLanguage,
        this.state.postId
      );
      this.setState({
        comment: ""
      });
    }
  };

  render() {
    const { messages, users, comments } = this.state;

    var commentsObject = [...this.state.comments];

    return (
      <div className="comments-container">
        <h3> Comments ({this.state.comment_count})</h3>
        <form onSubmit={this.onCommentSubmit}>
          <textarea
            className="comment-box"
            placeholder="Write a comment..."
            value={this.state.comment}
            onChange={event =>
              this.setState(updateByPropertyName("comment", event.target.value))
            }
          />
          <button
            className={`px-8 py-3 my-2 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-blue-600 hover:bg-blue-500 focus:outline-none focus:border-blue-800 focus:shadow-outline-blue transition duration-150 ease-in-out md:py-4 md:text-lg md:px-10 cursor-pointer`}
            type="submit">
            Post
          </button>
        </form>

        <div className="comments">
          {commentsObject
            .sort((a, b) => a.timestamp > b.timestamp)
            .map((comment, i) => {
              return (
                <Comment
                  key={i}
                  comment={comment}
                  user={users[comment.uid]}
                  timestamp={comment.timestamp}
                />
              );
            })
            .reverse()}
        </div>
      </div>
    );
  }
}

export default Comments;

const Comment = ({ comment, user, timestamp }) => {
  const commenter = user ? `${user.username}` : "...";
  const id = user ? `${user.id}` : "...";
  let imageURL = "/img/avatar.png";
  if (user) {
    imageURL = user.photoURL || "/img/avatar.png";
  }

  function highlight(string) {
    return string.replace(/\B\@([\w\-]+)/gim, function(str) {
      return (
        "<a href='/user/" +
        str.substring(1) +
        "'>" +
        "<span class='mention'>" +
        str +
        "</span></a>"
      );
    });
  }

  let datetime = "...";
  if (timestamp && typeof window !== "undefined") {
    let date = new window.Date(timestamp);
    datetime = date.toLocaleString();
  }

  return (
    <div>
      <div className="user-comment">
        <div className="commenter">
          <Link to={`/user/${id}`}>
            <img className="profile-image-comment-message" src={imageURL} />
          </Link>
          <span>
            {commenter}
            <Link to={`/user/${id}`}> @{id}</Link>
            <p className="comment-timestamp">
              {new Intl.DateTimeFormat("en-US", {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
                hour: "2-digit",
                minute: "2-digit",
                second: "2-digit"
              }).format(timestamp)}
            </p>
          </span>
        </div>
        <div className="text">
          <Markdown options={{ html: true, linkify: true }}>
            {highlight(comment.comment)}
          </Markdown>
        </div>
      </div>
      <br />
    </div>
  );
};
