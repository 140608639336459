import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { pushToUserActivity, userActivityTutorialRef } from "api/db";
import AuthUserContext from "../Session/AuthUserContext";

const Fade = styled.div`
  width: 100%;
  height: 200px;
  position: absolute;
  top: 800px;
  z-index: 2;
  background-image: linear-gradient(
    to bottom,
    rgba(250, 250, 250, 0.4),
    rgba(255, 255, 255, 1)
  );
`;

const Box = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 1000px;
  z-index: 2;
  background: rgba(255, 255, 255);
  text-align: center;

  a {
    display: inline;
    padding: 8px;
    cursor: pointer;
    border: 4px solid #0394fc;
    margin-right: 8px;
    color: #333;
    border-radius: 4px;
    transition: all 300ms ease;
    font-weight: bold;
    &:hover {
      cursor: pointer;
      color: #fff;
      background: #0394fc;
    }
  }
`;

const ReadMoreComponent = ({ projectSlug, projectId, tutorialStarted }) => (
  <AuthUserContext.Consumer>
    {(authUser) =>
      authUser ? (
        <ReadMore
          projectSlug={projectSlug}
          projectId={projectId}
          uid={authUser.uid}
          tutorialStarted={tutorialStarted}
        />
      ) : (
        <ReadMore
          projectSlug={projectSlug}
          projectId={projectId}
          uid={null}
          tutorialStarted={tutorialStarted}
        />
      )
    }
  </AuthUserContext.Consumer>
);

function ReadMore({ projectSlug, projectId, uid, tutorialStarted }) {
  const [showOverlay, setShowOverlay] = useState(true);
  if (tutorialStarted && showOverlay) {
    setShowOverlay(false);
  }

  function readMoreClick() {
    // if (process.env.NODE_ENV === "production") {
    //   typeof window !== "undefined" &&
    //     window.gtag("event", "click", {
    //       event_label: `${projectSlug}`,
    //       event_category: "read more",
    //     });
    // }

    if (uid) {
      userActivityTutorialRef(uid, projectId).once("value", (snapshot) => {
        let data = snapshot.val();
        if (!data) {
          pushToUserActivity(uid, projectSlug, projectId);
        } 
      });
    }
  }

  return (
    <>
      {showOverlay ? (
        <div>
          <Fade />
          <Box>
            <h2>Interested in this project?</h2>
            <a
              onClick={() => {
                readMoreClick();
                setShowOverlay(false);
              }}
            >
              Continue Learning
            </a>
          </Box>
        </div>
      ) : null}
    </>
  );
}

export default ReadMoreComponent;
